import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';
import { RootState } from 'initializers/types';
import { createSelector } from 'reselect';

import { MANAGER_LOG_SLICE } from './constants';

export interface Note {
  id: number;
  text: string;
  log_date: string;
  created_at: string;
  avatar_url: string;
  manager_name: string;
}

export interface Board {
  id: number;
  name: string;
  description: string | null;
  notes: Note[];
  showBanner?: boolean;
  archived_at: string;
}

export interface LaborAndSalesData {
  event_name?: string;
  degrees_max?: number;
  degrees_min?: number;
  weather_icon?: string;
  has_timecards?: boolean;
  labor_today?: string;
  labor_yesterday?: string;
  labor_percentage_today?: number;
  labor_percentage_yesterday?: number;
  sales_today?: string;
  sales_yesterday?: string;
}

export interface LaborStats {
  num_shifts?: number;
  total_hours?: number;
  missed_breaks?: number;
  labor_today?: string;
  labor_last_week?: string;
  sales_today?: string;
  sales_last_week?: string;
  labor_percentage_today?: number;
  labor_percentage_last_week?: number;
  overtime_costs?: string;
  day_of_week?: string;
}

export interface ManagerLogState {
  date: string;
  boards: Board[];
  boardsLoading: boolean;
  error: string | null;
  showBanner: boolean;
  laborAndSales: {
    data: LaborAndSalesData | null;
    loading: boolean;
    error: string | null;
  };
  laborStats: {
    data: LaborStats | null;
    loading: boolean;
    error: string | null;
  };
}

const initialState: ManagerLogState = {
  date: '',
  boards: [],
  boardsLoading: false,
  error: null,
  showBanner: false,
  laborAndSales: {
    data: null,
    loading: false,
    error: null,
  },
  laborStats: {
    data: null,
    loading: false,
    error: null,
  },
};

// Thunks
export const fetchBoards = createAsyncThunk(
  `${MANAGER_LOG_SLICE}/fetchBoards`,
  (date: string) =>
    fetchJSON(`/manager_log?date=${date}`, {
      // Headers needed to return JSON
      headers: {
        Accept: 'application/json',
      },
    })
);

export const fetchLaborAndSales = createAsyncThunk(
  `${MANAGER_LOG_SLICE}/fetchLaborAndSales`,
  (date: string) =>
    fetchJSON(`/manager_log/widgets/labor_and_sales?date=${date}`, {
      headers: {
        Accept: 'application/json',
      },
    })
);

export const fetchLaborStats = createAsyncThunk(
  `${MANAGER_LOG_SLICE}/fetchLaborStats`,
  (date: string) =>
    fetchJSON(`/manager_log/widgets/labor_stats?date=${date}`, {
      headers: {
        Accept: 'application/json',
      },
    })
);

// Slice
const managerLogSlice = createSlice({
  name: MANAGER_LOG_SLICE,
  initialState,
  reducers: {
    setDate(state, action) {
      state.date = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchBoards.pending, state => {
        state.boardsLoading = true;
        state.error = null;
      })
      .addCase(fetchBoards.fulfilled, (state, action) => {
        state.boardsLoading = false;
        state.boards = action.payload;
      })
      .addCase(fetchBoards.rejected, (state, action) => {
        state.boardsLoading = false;
        state.error = action.error.message || 'Failed to fetch boards';
      })
      .addCase(fetchLaborAndSales.pending, state => {
        state.laborAndSales.loading = true;
        state.laborAndSales.error = null;
      })
      .addCase(fetchLaborAndSales.fulfilled, (state, action) => {
        state.laborAndSales.loading = false;
        state.laborAndSales.data = action.payload;
      })
      .addCase(fetchLaborAndSales.rejected, (state, action) => {
        state.laborAndSales.loading = false;
        state.laborAndSales.error =
          action.error.message || 'Failed to fetch labor and sales data';
      })
      .addCase(fetchLaborStats.pending, state => {
        state.laborStats.loading = true;
        state.laborStats.error = null;
      })
      .addCase(fetchLaborStats.fulfilled, (state, action) => {
        state.laborStats.loading = false;
        state.laborStats.data = action.payload;
      })
      .addCase(fetchLaborStats.rejected, (state, action) => {
        state.laborStats.loading = false;
        state.laborStats.error =
          action.error.message || 'Failed to fetch labor stats data';
      });
  },
});

// Actions
export const { setDate } = managerLogSlice.actions;

// Selectors
export const selectDate = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).date;

export const selectBoards = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).boards;

export const selectBoardsLoading = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).boardsLoading;

export const selectShowBanner = createSelector([selectBoards], boards => {
  const managerNotesBoard = boards?.find(
    (board: Board) => board.name === 'Manager Notes'
  );
  return !!managerNotesBoard?.showBanner;
});

export const selectLaborAndSales = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).laborAndSales.data;

export const selectLaborAndSalesLoading = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).laborAndSales.loading;

export const selectLaborAndSalesError = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).laborAndSales.error;

export const selectLaborStats = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).laborStats.data;

export const selectLaborStatsLoading = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).laborStats.loading;

export const selectLaborStatsError = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).laborStats.error;

export const { reducer } = managerLogSlice;
